import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  IAutocomplete,
  IAutocompleteNormalizedResponse,
  IAutocompleteQueryParam,
  IAutocompleteRawResponse,
} from './typings';
import { QUERIES } from './constants';

export const autocompleteAdapter = createEntityAdapter({
  selectId: (item: IAutocomplete) => item?._id,
  // sortComparer: (a, b) => b?.userName.localeCompare(a?.userName),
  sortComparer: (a, b) => {
    if (!a?.userName || !b?.userName) return 0; // Handle null or undefined userName
    return b?.userName.localeCompare(a?.userName);
  },
});

export const autocompleteSelector = autocompleteAdapter.getSelectors();

export const autocompleteApiService = (baseQuery: BaseQueryFn) =>
  createApi({
    reducerPath: 'autocomplete-api',
    baseQuery,
    tagTypes: [QUERIES.userSuggestions],
    endpoints: (builder) => ({
      getUserSuggestions: builder.query<IAutocompleteNormalizedResponse, IAutocompleteQueryParam>({
        query: ({ userId, prefix, stage, creator_id }) => ({
          url: `public/v1/forum/users_suggestions/${userId}`,
          params: { ...(prefix && { prefix }), stage, creator_id },
        }),
        providesTags: [QUERIES.userSuggestions],
        transformResponse: (response: IAutocompleteRawResponse) => ({
          suggestions: autocompleteAdapter.setAll(
            autocompleteAdapter.getInitialState(),
            Array.isArray(response.recomended_users) ? response.recomended_users : [],
          ),
        }),
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePrefix = currentArg?.prefix === previousArg?.prefix;
          const isSameStage = currentArg?.stage === previousArg?.stage;
          return !isSamePrefix || !isSameStage;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs?.prefix}-${queryArgs?.stage}`,
        merge: (currentState, incomingState, meta) => {
          autocompleteAdapter.addMany(
            currentState.suggestions,
            autocompleteSelector.selectAll(incomingState.suggestions),
          );
        },
      }),
    }),
  });
