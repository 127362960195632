import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import {
  ICreateAccountResponse,
  IOnboardingUrlResponse,
  IStripeDashboardLinkResponse,
  ICreatorProduct,
  ICreatorProductBase,
  ICreatorProductNormalizedResponse,
  ICreatorProductQueryParam,
  TCreatorProductRawResponse,
  TCreatorProductUpdate,
  IUserSubscription,
  TUserSubscriptionRawResponse,
  IUserSubscriptionQueryParam,
  IUserSubscriptionNormalizedResponse,
  ICreateDonationPayload,
  IDonation,
  IDonationQueryParam,
  IDonationRawPaginatedResponse,
  IDonationNormalizedPaginatedResponse,
  IPaymentTransaction,
  ITransactionNormalizedPaginatedResponse,
  ITransactionQueryParam,
  ITransactionRawPaginatedResponse,
  IUserSubscriptionActionPayload,
  IUserSubscriptionActionResponse,
  IUserSubscriptionsResponse,
} from './typings';
import { QUERIES } from './constants';
import { createEntityAdapter } from '@reduxjs/toolkit';

export const creatorProductAdapter = createEntityAdapter({
  selectId: (item: ICreatorProduct) => item._id,
  // sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
  // sortComparer: (a, b) => a.price - b.price,
});

export const creatorProductSelector = creatorProductAdapter.getSelectors();

export const userSubscriptionAdapter = createEntityAdapter({
  selectId: (item: IUserSubscription) => item._id,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

export const userSubscriptionSelector = userSubscriptionAdapter.getSelectors();

export const donationAdapter = createEntityAdapter({
  selectId: (item: IDonation) => item._id,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

export const donationSelector = donationAdapter.getSelectors();

export const transactionAdapter = createEntityAdapter({
  selectId: (item: IPaymentTransaction) => item._id,
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

export const transactionSelector = transactionAdapter.getSelectors();

export const monetizationApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'monetization-api',
    baseQuery,
    tagTypes: [
      QUERIES.monetizationOnboarding,
      QUERIES.monetizationCreatorProducts,
      QUERIES.monetizationUserSubscriptions,
      QUERIES.monetizationDonations,
    ],
    endpoints: (builder) => ({
      createStripeConnectAccount: builder.mutation<ICreateAccountResponse, { email: string }>({
        query: (accountData) => ({
          url: 'billing/v1/create',
          method: 'POST',
          body: accountData,
        }),
        invalidatesTags: [QUERIES.monetizationOnboarding],
      }),
      getOnboardingUrl: builder.query<IOnboardingUrlResponse, { accountId: string }>({
        query: ({ accountId }) => ({
          url: `billing/v1/onboarding/${accountId}`,
          method: 'GET',
        }),
        providesTags: (result, error, { accountId }) => [
          { type: QUERIES.monetizationOnboarding, id: accountId },
        ],
      }),
      getStripeDashboardLink: builder.query<IStripeDashboardLinkResponse, { email: string }>({
        query: ({ email }) => ({
          url: 'billing/v1/stripe-dashboard',
          method: 'GET',
          params: { email },
        }),
        providesTags: (result, error, { email }) => [
          { type: QUERIES.monetizationOnboarding, id: email },
        ],
      }),
      createProductTier: builder.mutation<ICreatorProduct, ICreatorProductBase>({
        query: (productData) => ({
          url: `billing/v1/products/${productData.creator_id}/create`,
          method: 'POST',
          body: productData,
        }),
        invalidatesTags: [QUERIES.monetizationCreatorProducts],
      }),
      getCreatorProducts: builder.query<
        ICreatorProductNormalizedResponse,
        ICreatorProductQueryParam
      >({
        query: ({ creator_id, product_ids, ...rest }) => ({
          url: `billing/v1/products/${creator_id}`,
          method: 'GET',
          params: { ...rest, product_ids: product_ids?.join(',') },
        }),
        providesTags: [QUERIES.monetizationCreatorProducts],
        transformResponse: (response: TCreatorProductRawResponse) => ({
          products: creatorProductAdapter.setAll(
            creatorProductAdapter.getInitialState(),
            response.products,
          ),
          total_pages: response.total_pages,
          total_records: response.total_records,
        }),
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          const isSameCreator = currentArg?.creator_id === previousArg?.creator_id;
          return !isSamePage || !isSameCreator;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs?.creator_id}-${queryArgs?.page}-${queryArgs?.product_ids}`,
        merge: (currentState, incomingState) => {
          creatorProductAdapter.setAll(
            currentState.products,
            creatorProductSelector.selectAll(incomingState.products),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
        },
      }),
      getProductById: builder.query<ICreatorProduct, { creator_id: string; product_id: string }>({
        query: ({ creator_id, product_id }) => ({
          url: `billing/v1/products/${creator_id}/${product_id}`,
          method: 'GET',
        }),
        providesTags: (result, error, { product_id }) => [
          { type: QUERIES.monetizationCreatorProducts, id: product_id },
        ],
      }),
      updateProductTier: builder.mutation<ICreatorProduct, TCreatorProductUpdate>({
        query: ({ _id, creator_id, ...rest }) => ({
          url: `billing/v1/products/${creator_id}/${_id}/update`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: [QUERIES.monetizationCreatorProducts],
      }),
      deleteProductTier: builder.mutation<
        { success: boolean },
        { creator_id: string; product_id: string }
      >({
        query: ({ creator_id, product_id }) => ({
          url: `billing/v1/products/${creator_id}/${product_id}/delete`,
          method: 'DELETE',
        }),
        invalidatesTags: [QUERIES.monetizationCreatorProducts],
      }),
      getUserSubscriptions: builder.query<
        IUserSubscriptionNormalizedResponse,
        IUserSubscriptionQueryParam
      >({
        query: ({ user_id, creator_id, product_ids, ...rest }) => ({
          url: `billing/v1/user_subscriptions/${user_id}/${creator_id}`,
          method: 'GET',
          params: { ...rest, product_ids: product_ids?.join(',') },
        }),
        providesTags: [QUERIES.monetizationUserSubscriptions],
        transformResponse: (response: TUserSubscriptionRawResponse) => ({
          user_subscriptions: userSubscriptionAdapter.setAll(
            userSubscriptionAdapter.getInitialState(),
            response.user_subscriptions,
          ),
          total_pages: response.total_pages,
          total_records: response.total_records,
        }),
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          const isSameCreator = currentArg?.creator_id === previousArg?.creator_id;
          const isSameUser = currentArg?.user_id === previousArg?.user_id;
          return !isSamePage || !isSameCreator || !isSameUser;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs?.user_id}-${queryArgs?.creator_id}-${queryArgs?.page}-${queryArgs?.product_ids}`,
        merge: (currentState, incomingState) => {
          userSubscriptionAdapter.setAll(
            currentState.user_subscriptions,
            userSubscriptionSelector.selectAll(incomingState.user_subscriptions),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
        },
      }),
      getUserSubscriptionById: builder.query<
        IUserSubscription,
        { user_id: string; user_subscription_id: string }
      >({
        query: ({ user_id, user_subscription_id }) => ({
          url: `billing/v1/user_subscriptions/user_subscription/${user_subscription_id}/${user_id}`,
          method: 'GET',
        }),
        providesTags: (result, error, { user_subscription_id }) => [
          { type: QUERIES.monetizationUserSubscriptions, id: user_subscription_id },
        ],
      }),
      createUserSubscription: builder.mutation<
        IUserSubscriptionActionResponse,
        IUserSubscriptionActionPayload
      >({
        query: ({ creator_product_id, user_id }) => ({
          url: `billing/v1/user_subscriptions/${creator_product_id}/${user_id}/create`,
          method: 'POST',
        }),
        invalidatesTags: [QUERIES.monetizationUserSubscriptions],
      }),
      upgradeUserSubscription: builder.mutation<
        IUserSubscriptionActionResponse,
        IUserSubscriptionActionPayload
      >({
        query: ({ creator_product_id, user_id }) => ({
          url: `billing/v1/user_subscriptions/${creator_product_id}/${user_id}/update`,
          method: 'POST',
        }),
        invalidatesTags: [QUERIES.monetizationUserSubscriptions],
      }),
      downgradeUserSubscription: builder.mutation<
        IUserSubscriptionActionResponse,
        IUserSubscriptionActionPayload
      >({
        query: ({ creator_product_id, user_id }) => ({
          url: `billing/v1/user_subscriptions/${creator_product_id}/${user_id}/update`,
          method: 'POST',
        }),
        invalidatesTags: [QUERIES.monetizationUserSubscriptions],
      }),
      cancelUserSubscription: builder.mutation<
        IUserSubscription,
        { user_subscription_id: string; user_id: string }
      >({
        query: ({ user_subscription_id, user_id }) => ({
          url: `billing/v1/user_subscriptions/${user_subscription_id}/${user_id}/cancel`,
          method: 'DELETE',
        }),
        invalidatesTags: [QUERIES.monetizationUserSubscriptions],
      }),
      resumeUserSubscription: builder.mutation<
        string,
        { user_subscription_id: string; user_id: string }
      >({
        query: ({ user_subscription_id, user_id }) => ({
          url: `billing/v1/user_subscriptions/${user_subscription_id}/${user_id}/resume_payment`,
          method: 'POST',
        }),
        invalidatesTags: [QUERIES.monetizationUserSubscriptions],
      }),
      createDonation: builder.mutation<
        {
          donation: IDonation;
          client_secret: string;
          creator_stripe_account_id: string;
          platform_flat_fee: number;
        },
        ICreateDonationPayload
      >({
        query: (donationData) => ({
          url: `billing/v1/donations/${donationData.creator_id}/${donationData.user_id}`,
          method: 'POST',
          body: { amount: donationData.amount },
        }),
        invalidatesTags: [QUERIES.monetizationDonations],
      }),
      getSentDonations: builder.query<IDonationNormalizedPaginatedResponse, IDonationQueryParam>({
        query: ({ user_id, page, pageSize }) => ({
          url: `billing/v1/donations/sent/${user_id}`,
          method: 'GET',
          params: { page, page_size: pageSize },
        }),
        providesTags: [QUERIES.monetizationDonations],
        transformResponse: (response: IDonationRawPaginatedResponse) => ({
          donations: donationAdapter.setAll(donationAdapter.getInitialState(), response.donations),
          page: response.page,
          page_size: response.page_size,
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.user_id}-${queryArgs.page}`,
        merge: (currentState, incomingState) => {
          donationAdapter.setAll(
            currentState.donations,
            donationSelector.selectAll(incomingState.donations),
          );
          currentState.page = incomingState.page;
          currentState.page_size = incomingState.page_size;
        },
      }),
      getFanSubscriptions: builder.query<IUserSubscriptionsResponse, { fan_id: string }>({
        query: ({ fan_id }) => ({
          url: `billing/v1/user_subscriptions_data/${fan_id}`,
          method: 'GET',
        }),
        providesTags: [QUERIES.monetizationDonations],
      }),
      getReceivedDonations: builder.query<
        IDonationNormalizedPaginatedResponse,
        IDonationQueryParam
      >({
        query: ({ creator_id, page, pageSize }) => ({
          url: `billing/v1/donations/received/${creator_id}`,
          method: 'GET',
          params: { page, page_size: pageSize },
        }),
        providesTags: [QUERIES.monetizationDonations],
        transformResponse: (response: IDonationRawPaginatedResponse) => ({
          donations: donationAdapter.setAll(donationAdapter.getInitialState(), response.donations),
          page: response.page,
          page_size: response.page_size,
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.creator_id}-${queryArgs.page}`,
        merge: (currentState, incomingState) => {
          donationAdapter.setAll(
            currentState.donations,
            donationSelector.selectAll(incomingState.donations),
          );
          currentState.page = incomingState.page;
          currentState.page_size = incomingState.page_size;
        },
      }),
      getTransactions: builder.query<
        ITransactionNormalizedPaginatedResponse,
        ITransactionQueryParam
      >({
        query: ({ user_id, transaction_type, status, page, pageSize }) => ({
          url: `billing/v1/transactions/${user_id}`,
          method: 'GET',
          params: { transaction_type, status, page, page_size: pageSize },
        }),
        providesTags: [QUERIES.monetizationTransactions],
        transformResponse: (response: ITransactionRawPaginatedResponse) => ({
          transactions: transactionAdapter.setAll(
            transactionAdapter.getInitialState(),
            response.transactions,
          ),
          page: response.page,
          page_size: response.page_size,
        }),
        serializeQueryArgs: ({ endpointName, queryArgs }) =>
          `${endpointName}-${queryArgs.user_id}-${queryArgs.transaction_type}-${queryArgs.status}-${queryArgs.page}`,
        merge: (currentState, incomingState) => {
          transactionAdapter.setAll(
            currentState.transactions,
            transactionSelector.selectAll(incomingState.transactions),
          );
          currentState.page = incomingState.page;
          currentState.page_size = incomingState.page_size;
        },
      }),
    }),
  });
  return api;
};
