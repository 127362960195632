import { createApi } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import { DATE_FORMATS } from '@constants/date.constants';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { IImageUploadResponse } from './types';
import { QUERY } from '@constants/query.constants';
import {
  IMessage,
  IMessagesItemResponse,
  IMessagesPayload,
  IMessagesResponse,
  IMessagesResult,
} from 'app/(app)/dashboard/messages-deprecated/messages.typings';
import {
  IGroupMessagePayload,
  IPatchMessagePayload,
  IPostMessagePayload,
  ISendMessagePayload,
} from 'app/(app)/create-message/create-message.typings';

export const messagesApi = createApi({
  reducerPath: 'messages-api',
  baseQuery: authQuery,
  tagTypes: [QUERY.messages, QUERY.singleMessage],
  endpoints: (builder) => ({
    getMessages: builder.query<IMessagesResult, IMessagesPayload>({
      query: ({ page, pageSize, draft }: IMessagesPayload) => {
        const userId = cookieService.getUserId();
        return {
          url: `/${
            draft ? 'draft-notifications' : 'send-notifications'
          }/${userId}?page=${page}&pageSize=${pageSize}`,
        };
      },
      transformResponse: (data: IMessagesResponse) => {
        return {
          messages: data.notifications.map((message) => ({
            title: message.name,
            message: message.description,
            date: dayjs(message.created_at).format(DATE_FORMATS.messages),
            image: message.imageUrl,
            draft: message.draft,
            id: message._id,
            created_at: message.created_at,
            groupId: message.groupId,
            email: message.email,
          })),
          pagination: {
            pages: data.pages,
          },
        };
      },
      providesTags: [QUERY.messages],
    }),
    sendMessages: builder.mutation<string, ISendMessagePayload>({
      async queryFn(message: ISendMessagePayload, _queryApi, _extra, api) {
        const companyId = cookieService.getUserId();
        const emails = message.email.split(',');

        const requests = emails.map((email, idx) => {
          return api({
            url: '/notification',
            method: 'POST',
            body: {
              name: message.title,
              description: message.description,
              richDescription: message.description,
              email: email.trim(),
              companyId,
              uniqueMessage: idx === 0 ? 1 : 0,
              imageUrl: message.imageUrl,
              draft: false,
            },
          });
        });

        let errors = 0;

        const result = await Promise.all(requests);

        result.forEach((result) => {
          if ('error' in result) {
            errors += 1;
          }
        });

        if (errors > 0) {
          const error = {
            error: {
              error: `${emails.length - errors} - sent, ${errors} - not found`,
            },
          };
          return error as any;
        }

        return {
          data: 'Success',
        };
      },
      invalidatesTags: () => [QUERY.messages],
    }),
    sendGroupMessage: builder.mutation({
      query: (message: IGroupMessagePayload) => {
        return {
          url: `/public/v1/cohort-notification/${message.groupId}`,
          method: 'POST',
          body: {
            subject: message.title,
            notificationData: {
              name: message.title,
              description: message.description,
              imageUrl: message.imageUrl,
              draft: false,
            },
          },
        };
      },
      invalidatesTags: () => [QUERY.messages],
    }),
    postMessage: builder.mutation({
      query: (message: IPostMessagePayload) => {
        const userId = cookieService.getUserId();

        return {
          url: '/notification',
          method: 'POST',
          body: {
            name: message.title,
            description: message.description,
            richDescription: message.description,
            userId: userId,
            uniqueMessage: 1,
            companyId: userId,
            imageUrl: message.imageUrl,
            draft: message.draft || false,
            groupId: message.groupId,
            email: message.email,
          },
        };
      },
      invalidatesTags: () => [QUERY.messages],
    }),
    patchMessage: builder.mutation({
      query: (message: IPatchMessagePayload) => {
        const userId = cookieService.getUserId();
        return {
          url: '/notification',
          method: 'PATCH',
          body: {
            id: message.id,
            name: message.title,
            description: message.description,
            richDescription: message.description,
            userId: userId,
            companyId: userId,
            imageUrl: message.imageUrl,
            draft: message.draft || false,
            groupId: message.groupId,
            email: message.email,
          },
        };
      },
      invalidatesTags: () => [QUERY.messages, QUERY.singleMessage],
    }),
    getSingleMessage: builder.query<IMessage, string>({
      query(id: string) {
        return {
          url: `/creator/notification/${id}`,
        };
      },
      transformResponse(message: IMessagesItemResponse) {
        return {
          title: message.name,
          message: message.description,
          date: dayjs(message.created_at).format(DATE_FORMATS.messages),
          image: message.imageUrl,
          draft: message.draft,
          id: message._id,
          created_at: message.created_at,
          groupId: message.groupId,
          email: message.email,
        };
      },
      providesTags: [QUERY.singleMessage],
    }),
    removeMessage: builder.mutation({
      query: (id: string) => {
        return {
          url: `/creator/notification/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [QUERY.messages],
    }),
    // TODO: make common
    postImage: builder.mutation({
      query: (image: File) => {
        const form = new FormData();
        form.append('file', image);
        return {
          url: '/logo-upload',
          method: 'POST',
          body: form,
          formData: true,
        };
      },
      transformResponse(data: IImageUploadResponse) {
        return data.location;
      },
    }),
  }),
});

export const {
  useGetMessagesQuery,
  usePostImageMutation,
  usePostMessageMutation,
  usePatchMessageMutation,
  useGetSingleMessageQuery,
  useRemoveMessageMutation,
  useSendMessagesMutation,
  useSendGroupMessageMutation,
} = messagesApi;
