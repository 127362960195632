import React, { useMemo, useState } from 'react';
import { INoteBook, INotebookStateCreator } from '@cms/services';
import { Banner, Text, Note, Icon, CustomTooltip } from '@ui/components';
import { ContentItemMenu } from './content-item-menu';
import { useRouter, useSearchParams } from 'next/navigation';
import dayjs from 'dayjs';
import { ContentItemDeleteModal } from './content-delete-modal';

interface IContentItemProps {
  notebook: INoteBook;
  state?: INotebookStateCreator;
  userType?: string;
}

export const NotebookItem: React.FC<IContentItemProps> = ({ notebook, state, userType }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [notebookToDelete, setNotebookToDelete] = useState<string | null>(null);

  const truncateText = (text: any, maxLength: any) => {
    if (!text) return '';
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const handleDelete = () => {
    setNotebookToDelete(notebook?.id || null);
    setDeleteModalOpen(true);
  };
  const router = useRouter();
  const params = useSearchParams();
  const creator_id = params.get('id');
  const route =
    userType === 'creator'
      ? `/dashboard/contents/editor?id=${notebook?.id}`
      : `/creator/contents/content?notebook_id=${notebook?.id}&id=${creator_id}`;

  const handlePublish = () => {
    router.push(route);
    setDropdownOpen(false);
  };

  const handleSettings = () => {
    router.push(`/dashboard/contents/settings?id=${notebook?.id}`);
    setDropdownOpen(false);
  };

  const notebookVisibilityTag = useMemo(() => {
    switch (notebook?.visibility) {
      case 'public':
        return 'Public';
      case 'private':
        return 'Private';
      case 'internal':
        return 'Private';
      case 'fans':
        return 'Free';
      case 'tiers':
        return 'Exclusive';
    }
  }, [notebook?.visibility]);
  const handleTooltipClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const dynamicDate = notebook?.date_of_publish ? notebook?.date_of_publish : notebook?.created_at;

  return (
    <div className="relative w-full min-h-[258px] border border-gray-300 rounded-md pb-4 shadow-sm cursor-pointer hover:shadow-md">
      <div
        className="relative px-10 bg-primaryLight w-full rounded-t-md pt-4"
        onClick={() => handlePublish()}>
        <Banner type="content" image={notebook?.banner_url} />
        {notebook?.accesses?.tiers.length > 0 && (
          <p
            className="border text-center w-fit bg-white text-black text-xs mt-2 py-1 px-4 absolute top-0 right-2 z-50 rounded-sm flex items-center"
            onClick={handleTooltipClick}>
            {notebook?.tier_names?.[0]}
            <span className="mx-1">
              {/* {notebook?.accesses.tiers.length > 1 && `+${notebook?.tier_names?.length - 1} more`} */}
              {notebook?.accesses.tiers?.length > 1 && `+${notebook?.tier_names?.length - 1}`}
            </span>
            {notebook?.accesses.tiers?.length > 1 && (
              <div className="relative">
                <CustomTooltip
                  message={`${notebook?.tier_names?.join(', ')}`}
                  place="top"
                  small={true}
                  className="!absolute !top-[-55px] !z-50"
                />
              </div>
            )}
          </p>
        )}
        {userType === 'fan' && !notebook?.has_access && (
          <div className="absolute bg-black/50 top-0 right-0 w-full h-full rounded-t-md flex items-center justify-center flex-col space-y-1 z-40">
            <div className="mt-8 flex items-center bg-white border hover:bg-slate-300 max-w-[200px] rounded-2xl py-2 text-sm space-x-1 px-6 font-semibold">
              <Icon type="lock" stroke="#000" />
              <span>Unlock content</span>
            </div>
            <div className="text-xs text-white text-center">Available by subscription</div>
          </div>
        )}
      </div>

      {userType === 'creator' && (
        <div className="absolute bottom-4 right-4 z-50">
          <ContentItemMenu
            settings={handleSettings}
            isOpen={dropdownOpen}
            onOpenChange={setDropdownOpen}
            onPublish={handlePublish}
            onDelete={handleDelete}
          />
        </div>
      )}

      {userType === 'creator' && (
        <ContentItemDeleteModal
          open={isDeleteModalOpen}
          setOpen={setDeleteModalOpen}
          notebookId={notebookToDelete}
          state={state!}
        />
      )}

      <div className="mt-2 pl-4 w-full" onClick={() => handlePublish()}>
        <Text
          type="m"
          className="text-base font-semibold text-gray-800 w-full max-w-[90%] lg:max-w-[80%] xl:max-w-[75%] pr-2 overflow-hidden text-ellipsis whitespace-nowrap">
          {notebook?.name}
        </Text>
        <Note type="xs" className="line-clamp-2 block">
          {truncateText(notebook?.description, 45)}
        </Note>
        <Note type="xs" className="text-xs font-light">
          {notebook?.date_of_publish ? 'Published' : 'Created'}{' '}
          {dayjs(dynamicDate).format('DD MMM')}
        </Note>
      </div>
    </div>
  );
};
