export const QUERIES = {
  notifications: 'notification',
};

export const FAN_COMMUNITY_ROUTES = {
  community: {
    community: '/creator/community',
    post: '/creator/community/post',
    comment: '/creator/community/post/comment',
    reply: '/creator/community/post/comment/reply',
  },
};

export const CREATOR_COMMUNITY_ROUTES = {
  community: {
    community: '/dashboard/community',
    post: '/dashboard/community/post',
    comment: '/dashboard/community/post/comment',
    reply: '/dashboard/community/post/comment/reply',
  },
};

export const FAN_WEB_URL = process.env.NEXT_PUBLIC_FAN_WEB_URL;
export const CREATOR_WEB_URL = process.env.NEXT_PUBLIC_CREATOR_WEB_URL;
