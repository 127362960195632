import React, { useRef } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from '@ui/components';
import { Button } from '@ui/components/ui/button';
import { Bolt, EllipsisVertical, FilePenLine, Trash } from 'lucide-react';

interface ContentItemMenuProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onPublish: () => void;
  settings: () => void;
  onDelete: () => void;
}

export const ContentItemMenu: React.FC<ContentItemMenuProps> = ({
  isOpen,
  onOpenChange,
  onPublish,
  settings,
  onDelete,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <DropdownMenu open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost" className="rounded">
          <EllipsisVertical />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-40" ref={dropdownRef}>
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={onPublish} className="space-x-2 cursor-pointer">
            <FilePenLine className="h-4 w-4 mr-2" />

            <span>Edit</span>
          </DropdownMenuItem>

          <DropdownMenuItem onClick={settings} className="space-x-2 cursor-pointer">
            <Bolt className="h-4 w-4 mr-2" />

            <span>Settings</span>
          </DropdownMenuItem>

          <DropdownMenuItem onClick={onDelete} className="text-red-500 space-x-2 cursor-pointer">
            <Trash className="h-4 w-4 mr-2" />
            <span>Delete</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
