import { Icon } from '@ui/components';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}
const LockedContent = ({ className }: Props) => {
  return (
    <div
      className={twMerge(
        'w-full h-[40vh] rounded-t-md flex items-center justify-center flex-col space-y-1 relative bg-white',
        className,
      )}>
      <div className="absolute top-0 left-0 right-0 h-20 bg-gradient-to-b from-white to-transparent" />
      <div className="cursor-pointer flex items-center bg-primary border max-w-[200px] rounded-2xl py-2 text-sm space-x-1 px-6 font-semibold">
        <Icon type="lock" stroke="#000" />
        <span>Unlock content</span>
      </div>
      <div className="text-xs text-center">Available by subscription</div>
    </div>
  );
};

export default LockedContent;
