import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createToast } from '@ui/components';

interface ToastState {
  badgeImageURL: string | null;
}

const initialState: ToastState = {
  badgeImageURL: null,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setBadgeImageURL: (state, action: PayloadAction<string | null>) => {
      state.badgeImageURL = action.payload;
    },
    messageSuccessToast: (state, action: PayloadAction<boolean>) => {
      createToast(
        {
          title: `${action.payload ? 'Draft' : 'Message'} created`,
          message: "You've created new message",
        },
        {
          type: 'success',
        },
      );
    },
    badgeSuccessToast: (state, action: PayloadAction<boolean>) => {
      createToast(
        {
          title: `${action.payload ? 'Draft Badge' : 'Badge'} created`,
          message: action.payload ? '' : "You've created new badge",
        },
        {
          type: 'success',
        },
      );
    },
  },
});

export const { messageSuccessToast, setBadgeImageURL, badgeSuccessToast } = toastSlice.actions;

export const toastReducer = toastSlice.reducer;
