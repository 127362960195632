export const DATA_ASSISTANCE_FORMATS = {
  chat: 'dddd HH:mma',
  divider: 'dddd, MMMM D',
};

export const CHAT_AUTHOR = {
  user: 'User',
  bot: 'LLM',
};

export const CHAT_LOADING = '{[IS_LOADING]}';
