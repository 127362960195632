import { authQuery } from '../config/query.config';
import { notificationApiService } from '@notification/service';

export const notificationApi = notificationApiService(authQuery);
export const {
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
