import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../config/query.config';
import {
  IConfirmResetResponse,
  IResetPasswordPayload,
  ISignUpPayload,
} from 'app/auth/auth.typigns';

export const authApi = createApi({
  reducerPath: 'auth-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    signIn: builder.mutation({
      query: (user) => ({
        url: '/link-auth',
        method: 'POST',
        body: user,
      }),
    }),
    signUp: builder.mutation<unknown, ISignUpPayload>({
      query(payload) {
        return {
          url: `/users`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    forgotPassword: builder.mutation<void, string>({
      query(email) {
        return {
          url: '/users/change-password',
          method: 'POST',
          body: {
            email,
          },
        };
      },
    }),
    confirmResetPassword: builder.mutation<IConfirmResetResponse, string>({
      query(token) {
        return {
          url: `/users/user-confirmation/${token}`,
          method: 'GET',
        };
      },
    }),
    resetPassword: builder.mutation<void, IResetPasswordPayload>({
      query({ token, password }) {
        return {
          url: '/users/password-reset',
          method: 'PUT',
          body: {
            new_password: password,
            token,
          },
        };
      },
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useForgotPasswordMutation,
  useConfirmResetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
