import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../config/query.config';
import { IBadgeResponse } from 'app/(app)/dashboard/badges/badges.typings';
import { IBadgePreviewItem } from 'app/collaboration/collaboration.typings';

export const badgePreviewApi = createApi({
  reducerPath: 'badge-preview-api',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCollaborationBadge: builder.query<IBadgePreviewItem, string>({
      query(badgeId) {
        return `/badges/checkout/${badgeId}?id=${badgeId}`;
      },
      transformResponse(response: IBadgeResponse) {
        return {
          image: response.external_url,
          name: response.name,
          type: response.type,
          color: response.custom_badge_color,
          caption: response.caption,
        };
      },
    }),
  }),
});

export const { useGetCollaborationBadgeQuery } = badgePreviewApi;
