import { createApi } from '@reduxjs/toolkit/query/react';
import { chatQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { IChatHistoryResponse } from 'app/(app)/dashboard/_pages/data-assistant/data-assistant.typings';
import dayjs from 'dayjs';
import { CHAT_LOADING } from 'app/(app)/dashboard/_pages/data-assistant/data-assistant.constants';

export const aiApi = createApi({
  reducerPath: 'ai-api',
  baseQuery: chatQuery, // TODO: use regular query
  endpoints: (builder) => ({
    getChatHistory: builder.query<IChatHistoryResponse, void>({
      query() {
        const userId = cookieService.getUserId();

        return {
          url: `/data-assistant/chat_history/${userId}`,
        };
      },
    }),
    postChatMessage: builder.mutation<{ response: string }, string>({
      query(question) {
        const userId = cookieService.getUserId();

        return {
          url: `/data-assistant/query`,
          method: 'POST',
          body: {
            user_id: userId,
            question,
          },
        };
      },
      async onQueryStarted(message: string, { dispatch, queryFulfilled }) {
        dispatch(
          aiApi.util.updateQueryData('getChatHistory', null, (chatHistory) => {
            chatHistory.chat_history.push({
              message,
              user: 'User',
              timestamp: dayjs().toISOString(),
            });
            chatHistory.chat_history.push({
              message: CHAT_LOADING,
              user: 'LLM',
              timestamp: dayjs().toISOString(),
            });
          }),
        );
        try {
          const result = await queryFulfilled;
          dispatch(
            aiApi.util.updateQueryData('getChatHistory', null, (chatHistory) => {
              const lastIndex = chatHistory.chat_history.length - 1;
              chatHistory.chat_history[lastIndex].message = result.data.response;
            }),
          );
        } catch (e) {
          dispatch(
            aiApi.util.updateQueryData('getChatHistory', null, (chatHistory) => {
              const lastIndex = chatHistory.chat_history.length - 1;
              chatHistory.chat_history[lastIndex].message = 'Oops, Error';
            }),
          );
        }
      },
    }),
  }),
});

export const { useGetChatHistoryQuery, usePostChatMessageMutation } = aiApi;
