import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
} from '@ui/components';
import { Spinner } from '@ui/components/atoms';
import { AlertDialogHeader, AlertDialogFooter } from '@ui/components/ui';
import { useClickOutside } from '@ui/hooks';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { INotebookStateCreator } from '@cms/services';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  notebookId: string | null;
  state?: INotebookStateCreator;
}

export const ContentItemDeleteModal = (props: Props) => {
  const router = useRouter();
  const ref = useClickOutside(() => props.setOpen(false));

  const handleDelete = async () => {
    if (!props.state?.onDeleteNotebook || !props.notebookId) {
      console.error('Delete operation failed: Missing required properties');
      return;
    }

    try {
      await props.state.onDeleteNotebook(props.notebookId);
    } catch (error) {
      console.error('Error deleting notebook:', error);
    }
  };

  const preventInteractionOutside = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (props.state?.isDeleteNotebookSuccess) {
      props.setOpen(false);
    }
  }, [props.state?.isDeleteNotebookSuccess]);

  // Early return if state is undefined
  if (!props.state) {
    return null;
  }

  return (
    <AlertDialog open={props.open}>
      <div onClick={preventInteractionOutside}>
        <AlertDialogContent ref={ref}>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete this content.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => props.setOpen(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              disabled={props.state.isDeletionLoading}
              className="bg-primary hover:bg-primaryHover">
              {props.state.isDeletionLoading && (
                <Spinner className="mr-2 animate-spin" fill="#2D3139" />
              )}
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </div>
    </AlertDialog>
  );
};
