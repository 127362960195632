import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit';
import {
  INoteBook,
  INoteBookNormalizedResponse,
  INoteBookQueryParam,
  TNotebookRawResponse,
  ICreateNoteBook,
  IUpdateNoteBook,
} from './typings';
import { QUERIES } from './constants';

export const notebookAdapter = createEntityAdapter({
  selectId: (notebook: INoteBook) => notebook.id,
  sortComparer: (a, b) => {
    if (!a?.created_at || !b?.created_at) return 0;
    return b.created_at.localeCompare(a.created_at);
  },
});

export const notebookSelector = notebookAdapter.getSelectors();

export const notebookApiService = (baseQuery: BaseQueryFn) => {
  const api = createApi({
    reducerPath: 'notebook-api',
    baseQuery,
    tagTypes: [QUERIES.exclusiveContents],
    endpoints: (builder) => ({
      getNotebooks: builder.query<INoteBookNormalizedResponse, INoteBookQueryParam>({
        query: ({ ...rest }) => ({
          url: 'public/v1/exclusive_content/notebook',
          params: rest,
        }),
        providesTags: [QUERIES.exclusiveContents],
        transformResponse: (response: TNotebookRawResponse) => {
          const initialState = notebookAdapter.getInitialState();
          return {
            notebooks: notebookAdapter.setAll(initialState, response.notebooks),
            total_pages: response.total_pages,
            total_records: response.total_records,
          };
        },
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          return !isSamePage;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs?.creator_id}-${queryArgs?.status}`;
        },
        merge: (currentState, incomingState) => {
          notebookAdapter.addMany(
            currentState.notebooks,
            notebookSelector.selectAll(incomingState.notebooks),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
        },
      }),
      getFanNotebooks: builder.query<
        INoteBookNormalizedResponse,
        {
          page: number;
          page_size: number;
          creator_id: string;
          fan_id: string;
          visibility: string;
        }
      >({
        query: (params) => ({
          url: 'public/v1/exclusive_content/fan_notebooks',
          params,
        }),
        providesTags: [QUERIES.exclusiveContents],
        transformResponse: (response: TNotebookRawResponse) => {
          const initialState = notebookAdapter.getInitialState();
          return {
            notebooks: notebookAdapter.setAll(initialState, response.notebooks),
            total_pages: response.total_pages,
            total_records: response.total_records,
          };
        },
        forceRefetch: ({ currentArg, previousArg }) => {
          const isSamePage = currentArg?.page === previousArg?.page;
          return !isSamePage;
        },
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          return `${endpointName}-${queryArgs?.creator_id}-${queryArgs?.visibility}`;
        },
        merge: (currentState, incomingState) => {
          notebookAdapter.addMany(
            currentState.notebooks,
            notebookSelector.selectAll(incomingState.notebooks),
          );
          currentState.total_pages = incomingState.total_pages;
          currentState.total_records = incomingState.total_records;
        },
      }),
      getNotebookById: builder.query<INoteBook, string>({
        query: (id) => ({ url: `public/v1/exclusive_content/notebook/${id}` }),
      }),
      getFanNotebookById: builder.query<
        INoteBook,
        {
          id: string;
          creator_id: string;
          fan_id: string;
        }
      >({
        query: ({ id, creator_id, fan_id }) => ({
          url: `public/v1/exclusive_content/fan_notebook/${id}`,
          params: { creator_id, fan_id },
        }),
        transformResponse: (response: { notebook: INoteBook }) => response.notebook,
      }),
      createNotebook: builder.mutation<INoteBook, ICreateNoteBook>({
        query: (notebookData) => ({
          url: 'public/v1/exclusive_content/notebook',
          method: 'POST',
          body: notebookData,
        }),
        transformResponse: (response: { notebook: INoteBook }) => response.notebook,
        invalidatesTags: [QUERIES.exclusiveContents],
      }),
      updateNotebook: builder.mutation<INoteBook, IUpdateNoteBook>({
        query: ({ id, queryParams, ...rest }) => ({
          url: `public/v1/exclusive_content/notebook/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        transformResponse: (response: { notebook: INoteBook }) => response.notebook,
        invalidatesTags: [QUERIES.exclusiveContents],
        async onQueryStarted({ id, ...rest }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData('getNotebooks', rest.queryParams, (draft) => {
              const notebook = notebookSelector.selectById(draft.notebooks, id);
              if (!notebook) return;

              notebookAdapter.updateOne(draft.notebooks, {
                id,
                changes: {
                  ...notebook,
                  ...rest,
                },
              });
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      }),
      deleteNotebook: builder.mutation<
        { success: boolean },
        { id: string; queryParams: INoteBookQueryParam }
      >({
        query: ({ id }) => ({
          url: `public/v1/exclusive_content/notebook/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, param) => [
          { type: QUERIES.exclusiveContents, id: param.id },
        ],
        async onQueryStarted({ id, queryParams }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            api.util.updateQueryData('getNotebooks', queryParams, (draft) => {
              notebookAdapter.removeOne(draft.notebooks, id);
            }),
          );

          try {
            await queryFulfilled;
          } catch (error) {
            patchResult.undo();
          }
        },
      }),
    }),
  });

  return api;
};
