import { challengeApiService } from '@challenge/service';
import { authQuery } from '../config/query.config';

export const challengeApi = challengeApiService(authQuery);
export const {
  useGetChallengesQuery,
  useGetChallengeByIdQuery,
  useCreateChallengeMutation,
  useUpdateChallengeMutation,
  useDeleteChallengeMutation,
} = challengeApi;
